import { Link, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface Props {
  className?: string;
  sx?: SxProps;
}

const Copyright: React.FunctionComponent<Props> = ({ className, sx }) => {
  return (
    <Typography className={className} variant="body2" color="textSecondary" align="center" sx={sx}>
      Created by{' '}
      <Link component="a" href="https://engined.net" target="_blank">
        engined
      </Link>{' '}
      &copy; {new Date().getFullYear()} [{ENV.BUILD_ID}]
    </Typography>
  );
};

Copyright.displayName = 'Copyright';

export default Copyright;
